import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

//React Icons
import { FaDiscord, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai"

import Tilt from 'react-parallax-tilt';

// importing CSS
import "../CSS/Contact/Contact.css"
import "../CSS/Global/Global.css"
import "../CSS/Global/Stickers.css"


import Aos from 'aos';
import "aos/dist/aos.css"
import axios from 'axios';

import Astra from "./Images/AstraPfp.png"

// importing components
import StickerWithPosition from './StickerWithPosition';

// importing stickers
import FishSticker from "./Stickers/FishSticker.png"

export default function Contact() {

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Discord Status
    </Tooltip>
  );

  const discordUrl = 'https://api.lanyard.rest/v1/users/743601359697477713';

  const [discordPulse, setDiscordPulse] = useState('')

  useEffect(() => {
    axios.get(discordUrl).then((discordResponse) => {
      const disVar = discordResponse.data.data.discord_status;


      if (disVar === 'online') {
        setDiscordPulse('discord-pulse-online')
      }

      else if (disVar === 'idle') {
        setDiscordPulse('discord-pulse-idle')
      }

      else if (disVar === 'dnd') {
        setDiscordPulse('discord-pulse-dnd')
      }

      else if (disVar === 'offline') {
        setDiscordPulse('discord-pulse-offline')
      }

    })
  })

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_cce2tzg', 'template_i3gb2es', form.current, 'CIjfNKb1UjuFlNTVl')
      .then((result) => {
        alert("Email Sent")
      }, (error) => {
        alert(error.text)
      })

  }


  Aos.init({
    duration: 500,
    once: true
  });









  return (
    <div className='Home-container'>

      <section className='Contact-Section'>


        {<form className='Contact-Form' ref={form} onSubmit={sendEmail} data-aos="fade-right">


          <div className='ContactSection-1'>
            <p className='Contact-Title'>Get in touch</p>
          </div>

          <div className='ContactSection-2'>
            <div className='mail-section'>
              <input className='Contact-Inputs' type="text" name="from_name" placeholder='Name' autoComplete='off' required={true} />
              <input className='Contact-Inputs' type="email" name="from_email" placeholder='Email' autoComplete='off' required={true} />
              <textarea className='Contact-Inputs Contact-textarea' name="message" placeholder='Message' required={true} />
              <input className='Contact-Submit' type="submit" value="Send" />
            </div>

          </div>

        </form>}

        <div className='Discord-section'>
          <div className='discord-pfp'>
            <img src={Astra} alt='Logo' className='Astra-Discord-Img' />
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <div className={`circle pulse ${discordPulse}`}></div>
            </OverlayTrigger>
          </div>

          <div className='discord-tag'>
            <p className='discord-tag-user'>Astra<span>180</span></p>
          </div>
        </div>


        <div className='Contact-Icons-Section'>
          <a href='https://discord.gg/wZdCddU6zz' target='_blank' rel='noreferrer' className='contact-icon-tag discord-icon'>
            <FaDiscord className='Contact-Icon' />
          </a>

          <a href='https://www.instagram.com/nethan_journey/' target='_blank' rel='noreferrer' className='contact-icon-tag instagram-icon'>
            <FaInstagram className='Contact-Icon' />
          </a>

          <a href='https://www.linkedin.com/in/nethan-nagendran/' rel='noreferrer' target='_blank' className='contact-icon-tag linkedin-icon'>
            <FaLinkedinIn className='Contact-Icon' />
          </a>

          <a href='https://github.com/nethann' target='_blank' rel='noreferrer' className='contact-icon-tag git-icon'>
            <AiFillGithub className='Contact-Icon' />
          </a>
        </div>


      </section>





    </div>
  )
}

