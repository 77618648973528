/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.5 public/Pickleball1.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/Pickleball1.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.065, 0.767, 0]} scale={[0.634, 0.634, 0.053]}>
        <mesh geometry={nodes.Cube010.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Cube010_1.geometry} material={materials['Material.003']} />
      </group>
      <mesh geometry={nodes.Cube006.geometry} material={materials['Material.003']} position={[0.065, -0.802, 0]} scale={[0.108, 0.296, 0.296]} />
      <mesh geometry={nodes.Sphere_Sphere002.geometry} material={materials['Material.007']} position={[4.341, -0.717, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.081} />
    </group>
  )
}

useGLTF.preload('/Pickleball1.glb')
